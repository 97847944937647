import ImageAvatar from "./assets/images/image-app.png";
import Icon1 from "./assets/images/icon-1.png";
import Icon2 from "./assets/images/icon-2.png";
import ImageMain from "./assets/images/img-main.gif";
import "./App.scss";

function App() {
  return (
    <div className="App">
      <header className="page-header">
        <div className="container wrapper">
          <div className="img-avatar">
            <img src={ImageAvatar} alt="" />
          </div>
          <ul className="socials">
            <li>
              <a
                href="https://t.me/BekantanNo1Tiktok"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={Icon1} alt="" />
              </a>
            </li>
            <li>
              <a
                href="https://x.com/BekantanNo1"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={Icon2} alt="" />
              </a>
            </li>
          </ul>
        </div>
      </header>
      <main className="page-main">
        <div className="container wrapper">
          <h3 className="page-title">$bekantan</h3>
          <p className="des">No.1 Monkey on Tiktok</p>
          <div className="img-main">
            <img src={ImageMain} alt="" />
          </div>
          <ul className="tools">
            <li>Dextools</li>
            <li>DexScreener</li>
          </ul>
          <a href="" className="btn-buy">
            buy now!
          </a>
          <p className="address">
            CA: AyBpwMTxpSiwfd5d1Fvqw4MGsuhyHqHKXZpfZs7pump
          </p>
        </div>
      </main>
      <footer className="page-footer">
        <div className="container wrapper">
          <p>BEKANTAN 2024 | All Right Reserved</p>
          <p>Contact: BETANKANONSOL@GMAIL.COM</p>
        </div>
      </footer>
    </div>
  );
}

export default App;
